<script setup lang="ts">
import { SDivider, SElevationTokens, SSheet } from 'sima-suite-components-vue3'
import { useHost } from '@/composables'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, ref } from 'vue'
import SWizard from '@/components/organims/SWizard/SWizard.vue'
import OcsBrandLogo from '@/components/atoms/OcsBrandLogo/OcsBrandLogo.vue'
import ButtonPanel from '@/components/organims/ButtonPanel/ButtonPanel.vue'
import { useLocale } from 'vuetify'
import ModifyAppointment from '@/containers/Modify/ModifyAppointment/ModifyAppointment.vue'
import { useAppointmentStore, useEditAppointmentStore } from '@/store'
import ModifyAdviser from '@/containers/Modify/ModifyAdviser/ModifyAdviser.vue'
import ModifySummary from '@/containers/Modify/ModifySummary/ModifySummary.vue'
import { useRouter } from 'vue-router'
import { ROUTE_APPOINTMENT_CONFIRM, ROUTE_APPOINTMENT_FAILURE } from '@/router/routeNames.ts'

const { brandLogo, brandColor } = useHost()

const isLoading = ref(false)

const logo = computed(() => {
  if (brandLogo.value) {
    return new URL(`../../assets/themes/logos/${brandLogo.value}`, import.meta.url).href
  }
  return ''
})

const { translate, language } = useTranslator()
const { current: currentLocale } = useLocale()
const editAppointmentStore = useEditAppointmentStore()
const appointmentStore = useAppointmentStore()
const router = useRouter()

currentLocale.value = language.value

const wizardTabs = ref<string[]>([
  translate('AppointmentWizard.Steps.Step4'),
  translate('AppointmentWizard.Steps.Step5'),
  translate('AppointmentWizard.Steps.Step7')
])

const step = ref<number>(0)
const steps = [ModifyAppointment, ModifyAdviser, ModifySummary]

const nextStep = () => {
  step.value++
}

const previousStep = () => {
  step.value--
}

const handleWizardUpdateStep = (value: number) => {
  step.value = value
}

const isNextDisabled = computed(() => {
  switch (step.value) {
    case 0: {
      const hasDateSelected = editAppointmentStore.workshopAvailabilityDate
      const hasHourSelected = Number.isNaN(editAppointmentStore.workshopAvailabilityRange)
      return !(hasDateSelected && !hasHourSelected)
    }
    case 1:
      return !editAppointmentStore.workshopAvailabilityAdviser
  }

  return false
})

const isFirstStep = computed(() => {
  return step.value === 0
})

const isLastStep = computed(() => {
  return step.value === steps.length - 1
})

const modifyAppointment = async () => {
  isLoading.value = true
  try {
    await editAppointmentStore.modifyAppointment()
    appointmentStore.wasSentRequest = true
    router.push({
      name: ROUTE_APPOINTMENT_CONFIRM,
      state: {
        title: translate('Success.ModifyAppointment.Title'),
        description: translate('Success.ModifyAppointment.Description')
      }
    })
  } catch (e) {
    appointmentStore.wasSentRequest = true
    router.push({
      name: ROUTE_APPOINTMENT_FAILURE,
      state: {
        title: translate('Error.ModifyAppointment.Title'),
        description: translate('Error.ModifyAppointment.Description')
      }
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <s-sheet
      :elevation="SElevationTokens.null"
      class="w-full bg-color-white md:ml-auto md:mr-auto md:mt-spacing-xxxl md:h-fit md:w-3/4 md:shadow-shadow-m lg:w-3/5 lg:max-w-[1200px]"
    >
      <div
        class="flex flex-col items-center gap-0 px-spacing-xxl py-spacing-s sm:ml-spacing-s sm:flex-row md:h-28 md:justify-evenly md:gap-spacing-xs"
      >
        <ocs-brand-logo :src="logo" :alt="translate('AppointmentWizard.LogoAlt')" />
        <div class="w-full">
          <s-wizard
            :color="brandColor"
            :step="step"
            :steps="wizardTabs"
            @update:step="handleWizardUpdateStep"
          />
        </div>
      </div>
      <s-divider />
      <div
        class="h-[calc(100dvh_-_17rem)] px-spacing-m pt-spacing-s sm:h-[calc(100dvh_-_18rem)] md:h-[calc(100dvh_-_23rem)]"
      >
        <keep-alive>
          <component :is="steps[step]" />
        </keep-alive>
      </div>
      <div
        class="fixed bottom-0 h-fit w-full rounded-b-2xl bg-color-white shadow-[0_-4px_4px_0_rgba(173,173,173,0.1)] sm:relative sm:shadow-none"
      >
        <button-panel
          :is-next-disabled="isNextDisabled"
          :is-first-step="isFirstStep"
          :is-last-step="isLastStep"
          :is-confirm-loading="isLoading"
          @click:next="nextStep"
          @click:back="previousStep"
          @click:confirm="modifyAppointment"
        />
      </div>
    </s-sheet>
  </div>
</template>
