import { ROUTE_EDIT_APPOINTMENT, ROUTE_MAIN } from '@/router/routeNames.ts'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const checkIfRedirectToMainView = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { enterpriseId, appointmentId } = to.query
  if (!enterpriseId || !appointmentId) {
    return next({
      name: ROUTE_MAIN
    })
  }

  return next()
}

export const fromEditViewGuard = (
  _to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (from.name !== ROUTE_EDIT_APPOINTMENT) {
    return next({
      name: ROUTE_MAIN
    })
  }

  return next()
}
