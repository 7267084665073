<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'

const { translate } = useTranslator()
type NoAvailabilityMessageProps = {
  workshopPhone: string
}
defineProps<NoAvailabilityMessageProps>()
</script>

<template>
  <div class="mx-spacing-s flex w-full flex-col gap-spacing-m text-sm font-normal">
    <p>{{ translate('AppointmentWizard.Steps.Step4.NoAvailability.TextA') }}</p>
    <p v-if="workshopPhone">
      {{ translate('AppointmentWizard.Steps.Step4.NoAvailability.TextB') }}
      <a class="text-blue-500" :href="`tel:${workshopPhone}`">{{ workshopPhone }}</a>
    </p>
    <p v-else>
      {{ translate('AppointmentWizard.Steps.Step4.NoAvailability.TextC') }}
    </p>
  </div>
</template>

<style scoped></style>
