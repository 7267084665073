<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'
import { useHost } from '@/composables'
import { SCheckbox, SCheckboxColor } from 'sima-suite-components-vue3'

const { useNewRGPDPolicy, webOwnerName, privacyPolicyUrl, conditionsUrl } = useHost()

const check = defineModel('check')

const { translate } = useTranslator()
</script>

<template>
  <div v-if="useNewRGPDPolicy" class="b3-typography flex flex-col gap-spacing-s">
    <div>
      <span class="font-bold">
        {{ translate('AppointmentWizard.Steps.Step6.Attendant') }}
      </span>
      <span>{{ webOwnerName! }}</span>
    </div>
    <div>
      <span class="font-bold">
        {{ translate('AppointmentWizard.Steps.Step6.Purpose') }}
      </span>
      {{ translate('AppointmentWizard.Steps.Step6.PurposeDetails') }}
    </div>
    <div>
      <span class="font-bold">
        {{ translate('AppointmentWizard.Steps.Step6.Rights') }}
      </span>
      {{ translate('AppointmentWizard.Steps.Step6.RightsDetails') }}
      <a
        :href="privacyPolicyUrl"
        target="_blank"
        rel="noreferrer noopener"
        class="text-color-primary-500"
      >
        {{ translate('AppointmentWizard.Steps.Step6.RightsLink') }}
      </a>
    </div>
  </div>
  <div v-else class="w-full">
    <div class="flex items-center justify-start">
      <div class="flex justify-center">
        <s-checkbox v-model:checked="check" hide-details :color="SCheckboxColor.primary" />
      </div>
      <a
        :href="conditionsUrl"
        class="b2-typography w-full text-color-primary-500 sm:ml-spacing-xs"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ translate('AppointmentWizard.Steps.Step6.Policies') }}
      </a>
    </div>
  </div>
  <div class="b3-typography mt-spacing-xs flex flex-col gap-spacing-xs text-color-neutral-300">
    <span>{{ translate('AppointmentWizard.Steps.Step6.RequiredNotes') }}</span>
    <span> {{ translate('AppointmentWizard.Steps.Step6.Notes') }}</span>
  </div>
</template>
