import { defineRule } from 'vee-validate'
import { required, max, min, email, integer, min_value, regex } from '@vee-validate/rules'
import { useTranslator } from 'sima-suite-translator-vue3'

defineRule('required', required)
defineRule('min', min)
defineRule('min_value', min_value)
defineRule('max', max)
defineRule('email', email)
defineRule('integer', integer)
defineRule('regex', regex)

const { translate } = useTranslator()

defineRule('requiredIf', (value: any, [target]: any, ctx: any) => {
  if (!ctx.form[target] && !value) {
    return translate('Error.Step.Availability.Required.Field', [ctx.name])
  }
  return true
})
