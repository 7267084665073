import { Theme } from '@/helpers/types'

const themes: Record<string, Theme> = {
  '9C8190EC': {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  '11A0B8F6': {
    brandColor: '#1976d2',
    brandFont: '',
    brandLogo: 'Logo_Honda_Coches.svg'
  },
  '33A91CE7': {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  '48E76FDC': {
    brandColor: '#1976d2',
    brandFont: '',
    brandLogo: 'Logo_Citroen.svg'
  },
  '56B6E050': {
    brandColor: '#272727',
    brandFont: 'AudiType-Normal.ttf',
    brandLogo: 'Logo_Audi.svg'
  },
  '179A9464': {
    brandColor: '#272727',
    brandFont: 'AudiType-Normal.ttf',
    brandLogo: 'Logo_Audi.svg'
  },
  '636E04D1': {
    brandColor: '#1976d2',
    brandFont: '',
    brandLogo: ''
  },
  '1533AD1F': {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  '273235BF': {
    brandColor: '#0099da',
    brandFont: '',
    brandLogo: 'Logo_Hyundai.svg'
  },
  '759263CD': {
    brandColor: '#272727',
    brandFont: 'AudiType-Normal.ttf',
    brandLogo: 'Logo_Audi.svg'
  },
  '999800D2': {
    brandColor: '#272727',
    brandFont: '',
    brandLogo: 'Logo_Ducati.svg'
  },
  99999999: {
    brandColor: '#272727',
    brandFont: '',
    brandLogo: 'Logo_Ducati.svg'
  },
  '08070543': {
    brandColor: '#4ba82e',
    brandFont: 'SkodaPro-Regular.ttf',
    brandLogo: 'Logo_Skoda.svg'
  },
  52312411: {
    brandColor: '#1976d2',
    brandFont: '',
    brandLogo: 'Logo_Honda_Motos.svg'
  },
  D9FEECAB: {
    brandColor: '',
    brandFont: '',
    brandLogo: 'Logo_MyCarFlix.svg'
  },
  ECC3A49E: {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  F49CB785: {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  JX4HJNC8: {
    brandColor: '#ffcc00',
    brandFont: '',
    brandLogo: 'Logo_Xtravans.svg'
  },
  '3IHCMZHX': {
    brandColor: '#0099da',
    brandFont: 'VWTextOffice-Regular.ttf',
    brandLogo: 'Logo_Volkswagen.svg'
  },
  '4FAD4028': {
    brandColor: '',
    brandFont: '',
    brandLogo: 'Logo_MyCarFlix.svg'
  },
  KAOTR3I0: {
    brandColor: '#0099da',
    brandFont: '',
    brandLogo: 'Logo_Cupra.svg'
  },
  '46FBA93F': {
    brandColor: '#0099da',
    brandFont: '',
    brandLogo: 'Logo_Seat.svg'
  },
  default: {
    brandColor: '#0099da',
    brandFont: '',
    brandLogo: ''
  }
}

export default themes
