<script setup lang="ts">
import {
  SAlertType,
  SCheckbox,
  SMainToolbar,
  SProgressCircular,
  STextarea,
  STextareaVariant
} from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
import { useAppointmentStore } from '@/store'
import { createARelevantMessage } from '@/utils'
import { Service } from '@/types.ts'

const { translate } = useTranslator()
const MAX_LENGTH = 70
const MIN_LENGTH = 5
const image = new URL(`../../assets/step-3.svg`, import.meta.url).href
const isLoading = ref(false)
const appointmentStore = useAppointmentStore()
const services = reactive<Service[]>([])

onMounted(async () => {
  isLoading.value = true
  try {
    await appointmentStore.getServices()

    Object.assign(
      services,
      appointmentStore.services.map((service) => {
        return { ...service, remarks: '', checked: false }
      })
    )
  } catch (error) {
    createARelevantMessage(translate('Error.Step.Service'), SAlertType.ERROR)
  } finally {
    isLoading.value = false
  }
})

const selectedServices = computed(() => {
  return services.filter((service) => service.checked)
})

const handleScroll = (currentService: Service) => {
  setTimeout(() => {
    if (currentService.hasRemarks) {
      document.getElementById(`${currentService.id}`)?.scrollIntoView(true)
    }
  }, 1)
}

const getErrors = (service: Service) => {
  if (service.remarks!.length > MAX_LENGTH && service.hasRemarks) {
    return [translate('AppointmentWizard.Steps.Step3.MaxLength', [MAX_LENGTH.toString()])]
  } else if (
    service.remarks!.length < MIN_LENGTH &&
    service.remarks!.length > 0 &&
    service.hasRemarks
  ) {
    return [translate('AppointmentWizard.Steps.Step3.MinLength', [MIN_LENGTH.toString()])]
  }
  return []
}

watchEffect(() => {
  appointmentStore.selectedServices = selectedServices.value
})
</script>

<template>
  <div class="flex h-full gap-spacing-m overflow-y-hidden p-spacing-s pt-spacing-s">
    <div class="hidden rounded-2xl border border-[#E0E0E0] px-spacing-l py-16 sm:block sm:w-3/5">
      <img :src="image" :alt="translate('AppointmentWizard.Steps.Step3.AltImage')" />
    </div>
    <div v-if="!isLoading" class="h-full w-full overflow-y-auto pb-spacing-s sm:w-2/5 sm:pb-0">
      <s-main-toolbar
        class="pb-spacing-s"
        :title="translate('AppointmentWizard.Steps.Step3.TitleForm')"
      />
      <template v-for="(service, index) in services" :key="index">
        <s-checkbox
          :id="service.id.toString()"
          v-model:checked="service.checked"
          :label="service.name"
          hide-details
          @update:checked="handleScroll(service)"
        />
        <s-textarea
          v-if="service.hasRemarks && service.checked"
          :key="index"
          v-model:value="service.remarks"
          class="p-spacing-s"
          :variant="STextareaVariant.filled"
          :counter="MAX_LENGTH"
          no-resize
          :label="translate('AppointmentWizard.Steps.Step3.TextAreaPlaceholder')"
          :error-messages="getErrors(service)"
        />
      </template>
    </div>
    <div v-else class="flex size-full items-center justify-center">
      <s-progress-circular />
    </div>
  </div>
</template>
