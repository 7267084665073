<template>
  <s-app class="bg-color-white sm:bg-color-neutral-50">
    <router-view />
    <div
      v-if="isVisibleBanner && !cookiesAccepted && showBanner"
      class="fixed bottom-0 left-0 z-[9999999999] w-full"
    >
      <s-banner
        :first-button-text="translate('AppointmentWizard.Cookies.LinkMessage')"
        :message="translate('AppointmentWizard.Cookies.Description')"
        :second-button-text="translate('AppointmentWizard.Cookies.CloseMessage')"
        icon="mdi-information"
        @first-button-click="openCookiesUrl"
        @second-button-click="acceptCookies"
      />
    </div>
    <div class="fixed bottom-0 z-10 w-full">
      <s-alerts-handler :messages="messages" @alert-message-closed="removeAlert" />
    </div>
    <div class="fixed bottom-0 h-fit w-20 sm:w-full">
      <footer-links v-if="useFooter" />
    </div>
  </s-app>
</template>

<script setup lang="ts">
import { SAlertsHandler, SApp, SBanner } from 'sima-suite-components-vue3'
import { useAlert, useHost } from '@/composables'
import FooterLinks from '@/components/molecules/FooterLinks/FooterLinks.vue'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onMounted, ref } from 'vue'
import { decodeCookie, getCookiesUrl } from '@/utils/CookiesWrapper.ts'

const { messages, removeAlert } = useAlert()
const { translate } = useTranslator()
const { useFooter, cookiesUrl, useLocalCookieBanner, brandFont } = useHost()
const showBanner = ref(true)

const isVisibleBanner = computed(() => useLocalCookieBanner.value)
const fontFamily = ref(brandFont.value ? 'Brand Font' : 'Roboto')

onMounted(() => {
  if (useLocalCookieBanner.value) {
    getCookiesUrl()
  }
})

if (brandFont.value) {
  const brandFontElement = document.createElement('style')

  brandFontElement.appendChild(
    document.createTextNode(`
        @font-face {
          font-family: 'Brand Font';
          src: url('/fonts/${brandFont.value}');
        }
      `)
  )

  document.head.appendChild(brandFontElement)
}

const cookiesAccepted = computed(() => decodeCookie('cookiesAccepted') === 'true')

const openCookiesUrl = () => {
  if (cookiesUrl.value) {
    window.open(cookiesUrl.value, '_blank')
  }
}

const acceptCookies = () => {
  document.cookie = 'cookiesAccepted=true'
  showBanner.value = false
}
</script>

<style>
* {
  font-family: v-bind(fontFamily) !important;
}
</style>
