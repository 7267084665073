import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import {
  createEmptyModifyCustomer,
  createEmptyModifyVehicle,
  createEmptyWorkshop
} from '@/helpers/constructors.ts'
import {
  AppointmentModificationRequest,
  IAvailableHourForAdviserDto,
  IWorkshopAvailabilityDto,
  IWorkshopAvailabilityDto2
} from 'online-customer-services-ts'
import dayjs from '@/utils/dayjs.ts'

import { useAutogenerateClients, useHost } from '@/composables'
import { createWorkshopDate } from '@/utils'
import { useTranslator } from 'sima-suite-translator-vue3'

const { getClient } = useAutogenerateClients()
const { id: hostId } = useHost()
const { language } = useTranslator()

export const useEditAppointmentStore = defineStore('editAppointmentStore', () => {
  const enterpriseId = ref('')
  const appointmentId = ref('')
  const appointment = reactive({
    workshop: createEmptyWorkshop(),
    vehicle: createEmptyModifyVehicle(),
    customer: createEmptyModifyCustomer(),
    adviserName: '',
    date: '',
    time: '',
    teamId: NaN,
    serviceTotalUdts: NaN
  })
  const workshopAvailabilityDate = ref('')
  const workshopAvailabilityRange = ref(NaN)
  const workshopAvailabilityAdviser = ref<IAvailableHourForAdviserDto | undefined>(undefined)
  const availabilityCalls = ref(0)
  const workshopAvailability = ref<IWorkshopAvailabilityDto2[]>([])

  const getAppointment = async () => {
    const appointmentClient = getClient('appointmentsClient')
    const {
      appointmentDateTime,
      workshop,
      vehicle,
      customer,
      adviserName,
      servicesTotalUdts,
      teamId
    } = await appointmentClient.get(hostId.value, enterpriseId.value, appointmentId.value)
    const dateTime = appointmentDateTime.split(' ')
    appointment.workshop = workshop!
    appointment.vehicle = vehicle!
    appointment.vehicle.brand =
      appointment.vehicle.brand!.charAt(0).toUpperCase() +
      appointment.vehicle.brand!.slice(1).toLowerCase()
    appointment.date = dateTime[0]
    appointment.time = dateTime[1]
    appointment.customer = customer!
    appointment.adviserName = adviserName!
    appointment.teamId = teamId!
    appointment.serviceTotalUdts = servicesTotalUdts!
  }

  const cancelAppointment = async () => {
    const appointmentClient = getClient('appointmentsClient')
    await appointmentClient.cancelAppointment(enterpriseId.value, appointmentId.value, hostId.value)
  }

  const modifyAppointment = async () => {
    const requestData = new AppointmentModificationRequest({
      workshopId: appointment.workshop.id,
      adviserId: workshopAvailabilityAdviser.value!.adviserId,
      teamId: appointment.teamId,
      date: createWorkshopDate(
        workshopAvailabilityDate.value,
        workshopAvailabilityAdviser.value!.adviserTime!
      ),
      cultureName: language.value
    })
    const appointmentClient = getClient('appointmentsClient')
    await appointmentClient.modifyAppointment(
      enterpriseId.value,
      appointmentId.value,
      requestData,
      hostId.value
    )
  }

  const availableDays = computed(() => {
    const availableDays = workshopAvailability.value.filter((day) => day.advisers!.length > 0)
    return availableDays.map((day) => {
      return new Date(dayjs(day.advisers![0].dateTime).format('YYYY-MM-DD'))
    })
  })
  const availableRangeHours = computed(() => {
    const availableDay: IWorkshopAvailabilityDto | undefined = workshopAvailability.value.find(
      (day: IWorkshopAvailabilityDto) => {
        if (day.advisers?.length === 0) return false

        return day.advisers![0].adviserDate === workshopAvailabilityDate.value
      }
    )
    if (availableDay) {
      const availableAdviserHours: number[] = availableDay.advisers!.map(
        (adviser: IAvailableHourForAdviserDto) => {
          return Number(adviser.adviserTime!.split(':')[0])
        }
      )
      return [...new Set(availableAdviserHours)]
    }

    return []
  })
  const availableAdvisers = computed(() => {
    const currentDateAvailability = workshopAvailability.value.find((day) => {
      if (day.advisers?.length === 0) return false

      return day.advisers![0].adviserDate === workshopAvailabilityDate.value
    })
    if (currentDateAvailability) {
      return currentDateAvailability.advisers!.filter(
        (adviser) => Number(adviser.adviserTime!.split(':')[0]) === workshopAvailabilityRange.value
      )
    }

    return []
  })

  const resetAvailability = () => {
    workshopAvailabilityDate.value = ''
    workshopAvailabilityRange.value = NaN
    workshopAvailabilityAdviser.value = undefined
    availabilityCalls.value = 0
    workshopAvailability.value = []
  }

  const getWorkshopAvailability = async (date: Date) => {
    const availabilityClient = getClient('workshopAvailability')

    const abbreviation = appointment.vehicle.brandCode

    const fromDate = createWorkshopDate(
      dayjs(date.toISOString()).format('YYYY-MM-DD'),
      appointment.workshop.startingWorkHour!
    )
    const toDate = createWorkshopDate(
      dayjs(date.toISOString()).format('YYYY-MM-DD'),
      appointment.workshop.startingWorkHour!
    )
    const selectedDate = dayjs(date.toISOString()).isToday()
      ? dayjs(date.toISOString()).add(appointment.workshop.daysOfMarginToRequest, 'day').toDate()
      : new Date(date)

    const { workshopAvailability: availability } =
      await availabilityClient.getWorkshopAvailability22(
        hostId.value,
        selectedDate,
        fromDate,
        toDate,
        appointment.workshop.id,
        [],
        abbreviation,
        appointment.vehicle.vin,
        appointment.serviceTotalUdts,
        appointment.teamId
      )
    workshopAvailability.value = availability
    availabilityCalls.value = availabilityCalls.value + 1
  }

  return {
    enterpriseId,
    appointmentId,
    appointment,
    workshopAvailabilityDate,
    workshopAvailabilityRange,
    workshopAvailabilityAdviser,
    availabilityCalls,
    workshopAvailability,
    getAppointment,
    availableDays,
    availableRangeHours,
    availableAdvisers,
    resetAvailability,
    getWorkshopAvailability,
    cancelAppointment,
    modifyAppointment
  }
})
