<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useTranslator } from 'sima-suite-translator-vue3'
import { useHost } from '@/composables'
import { isMobile, SIconButton, SDivider } from 'sima-suite-components-vue3'
import { ROUTE_COOKIES_POLICY } from '@/router/routeNames.ts'

const router = useRouter()
const { translate } = useTranslator()
const { legalAdviserUrl, privacyPolicyUrl, didomiId } = useHost()

const isOpenModal = ref<boolean | undefined>(undefined)

const toggleModal = () => {
  isOpenModal.value = !isOpenModal.value
}

const navigateToCookiesPolicy = () => {
  const cookiesPolicyRoute = router.resolve({ name: ROUTE_COOKIES_POLICY })
  window.open(cookiesPolicyRoute.href, '_blank')
}

const legalAdviser = computed(() => legalAdviserUrl.value)
const privacyPolicy = computed(() => privacyPolicyUrl.value)
const isDidomiId = computed(() => didomiId.value !== '')

watch(
  () => isMobile.value,
  () => {
    isOpenModal.value = undefined
  }
)
</script>

<template>
  <footer>
    <s-icon-button
      v-if="isMobile && !isOpenModal"
      :title="translate('AppointmentWizard.Footer.OpenFooter')"
      class="absolute bottom-0 left-0 mb-6 ml-spacing-s"
      color="#b5b5b6"
      icon="mdi-information-outline"
      @icon-button-click="toggleModal"
    />
    <s-divider v-if="!isMobile" />
    <section
      :class="{
        hidden: isOpenModal === undefined && isMobile,
        'open-animation': isOpenModal === true && isMobile,
        'close-animation': isOpenModal === false && isMobile
      }"
      class="absolute bottom-0 left-0 z-10 w-dvw bg-white sm:h-auto lg:bg-color-neutral-50"
    >
      <div v-if="isMobile" class="flex items-center justify-end px-spacing-m pt-16">
        <s-icon-button
          :title="translate('AppointmentWizard.Footer.CloseFooter')"
          icon="mdi-close"
          @icon-button-click="toggleModal"
        />
      </div>
      <div
        class="flex w-full flex-col items-center gap-10 pt-20 sm:flex-row sm:items-center sm:justify-evenly sm:gap-spacing-s sm:pt-0 sm:text-sm lg:justify-center lg:gap-20"
      >
        <a
          :href="legalAdviser"
          class="!text-color-neutral-300 sm:px-spacing-s sm:py-spacing-s sm:hover:bg-color-neutral-150 lg:px-spacing-l"
          target="_blank"
        >
          {{ translate('AppointmentWizard.Footer.LegalAdviser') }}
        </a>
        <a
          :href="privacyPolicy"
          class="!text-color-neutral-300 sm:px-spacing-s sm:py-spacing-s sm:hover:bg-color-neutral-150 lg:px-spacing-l"
          target="_blank"
        >
          {{ translate('AppointmentWizard.Footer.PrivacyPolicy') }}
        </a>
        <a
          class="cursor-pointer !text-color-neutral-300 sm:px-spacing-s sm:py-spacing-s sm:hover:bg-color-neutral-150 lg:px-spacing-l"
          @click="navigateToCookiesPolicy"
        >
          {{ translate('AppointmentWizard.Footer.CookiePolicies') }}
        </a>
        <a
          v-if="isDidomiId"
          class="!text-color-neutral-300 sm:px-spacing-s sm:py-spacing-s sm:hover:bg-color-neutral-150 lg:px-spacing-l"
          href="javascript:Didomi.preferences.show()"
        >
          {{ translate('AppointmentWizard.Footer.SelectionConsents') }}
        </a>
      </div>
    </section>
  </footer>
</template>

<style scoped>
@keyframes openmodal {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

@keyframes closemodal {
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
}

.close-animation {
  position: relative;
  animation-name: closemodal;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0, 1.22, 0.27, 1.02);
  animation-fill-mode: forwards;
}

.open-animation {
  position: relative;
  animation-name: openmodal;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0, 1.22, 0.27, 1.02);
  animation-fill-mode: forwards;
}
</style>
