<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'
import { SIconButton, SList, SMainToolbar } from 'sima-suite-components-vue3'
import WorkshopMap from '@/components/organims/WorkshopMap/WorkshopMap.vue'
import { useAppointmentStore } from '@/store'
import { computed } from 'vue'
import { ContactDetails } from '@/store/appointment/types.ts'

const appointmentStore = useAppointmentStore()

const { translate, locale } = useTranslator()

const details = computed(() => {
  return [
    {
      primaryText: appointmentStore.workshopSelected.name!,
      secondaryText: `${appointmentStore.workshopSelected.addressPart1!}`,
      showFullText: true,
      icon: 'mdi-map-marker-outline'
    },
    {
      primaryText: `${appointmentStore.vehicle.brand?.name!} ${appointmentStore.vehicle.model!}`,
      secondaryText: appointmentStore.vehicle.plate,
      additionalText: `${appointmentStore.vehicle.kilometers} km`,
      showFullText: true,
      icon: 'mdi-car-outline'
    },
    {
      primaryText: `${appointmentStore.selectedServices.length} ${appointmentStore.selectedServices.length > 1 ? translate('AppointmentWizard.Steps.Step7.Services') : translate('AppointmentWizard.Steps.Step7.Service')}`,
      secondaryText: appointmentStore.selectedServices.map((service) => service.name).join(', '),
      showFullText: true,
      icon: 'mdi-wrench-outline'
    },
    {
      primaryText: new Date(appointmentStore.workshopAvailabilityDate).toLocaleString(
        locale.value,
        { year: 'numeric', month: 'long', day: 'numeric', hour12: false }
      ),
      secondaryText: appointmentStore.workshopAvailabilityAdviser?.adviserTime,
      showFullText: true,
      icon: 'mdi-calendar-outline'
    },
    {
      primaryText: appointmentStore.contactDetails.name,
      secondaryText: appointmentStore.contactDetails.email,
      additionalText: phonesToText(appointmentStore.contactDetails),
      showFullText: true,
      icon: 'mdi-account-outline'
    }
  ]
})

const phonesToText = (contact: ContactDetails): string => {
  return [contact.mobilePhone, contact.phone].filter(Boolean).join(' - ')
}
</script>

<template>
  <div class="flex h-full w-full overflow-y-hidden p-spacing-s pt-spacing-s sm:gap-spacing-m">
    <div class="sm:w-4/6">
      <workshop-map
        :workshops="[appointmentStore.workshopSelected]"
        :current-workshop="appointmentStore.workshopSelected"
      />
    </div>

    <div class="w-full overflow-y-hidden sm:w-2/5">
      <s-main-toolbar
        class="pb-spacing-s"
        :title="translate('AppointmentWizard.Steps.Step7.Summary')"
      />
      <div class="h-full overflow-y-auto pb-spacing-xl">
        <s-list :items="details">
          <template #prependItem="detail">
            <div class="mr-spacing-xs">
              <s-icon-button title="icon" :icon="detail.icon as unknown as string" />
            </div>
          </template>
        </s-list>
      </div>
    </div>
  </div>
</template>
