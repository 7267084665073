import { reactive, toRefs } from 'vue'

type Host = {
  brandColor: string
  brandFont: string
  brandLogo: string
  conditionsUrl: string
  cookiesUrl: string
  didomiId: string
  id: string
  legalAdviserUrl: string
  mapsKey: string
  privacyPolicyUrl: string
  useFooter: boolean
  useLocalCookieBanner: boolean
  useNewRGPDPolicy: boolean
  webOwnerName: string
}
const host = reactive<Host>({
  brandColor: '',
  brandFont: '',
  brandLogo: '',
  conditionsUrl: '',
  cookiesUrl: '',
  didomiId: '',
  id: '',
  legalAdviserUrl: '',
  mapsKey: '',
  privacyPolicyUrl: '',
  useFooter: false,
  useLocalCookieBanner: false,
  useNewRGPDPolicy: false,
  webOwnerName: ''
})
export const useHost = () => {
  const initializeHost = (
    configuration: Host = {
      brandColor: '',
      brandFont: '',
      brandLogo: '',
      conditionsUrl: '',
      cookiesUrl: '',
      didomiId: '',
      id: '',
      legalAdviserUrl: '',
      mapsKey: '',
      privacyPolicyUrl: '',
      useFooter: false,
      useLocalCookieBanner: false,
      useNewRGPDPolicy: false,
      webOwnerName: ''
    }
  ) => {
    host.brandColor = configuration.brandColor
    host.brandFont = configuration.brandFont
    host.brandLogo = configuration.brandLogo
    host.conditionsUrl = configuration.conditionsUrl
    host.cookiesUrl = configuration.cookiesUrl
    host.didomiId = configuration.didomiId
    host.id = configuration.id
    host.legalAdviserUrl = configuration.legalAdviserUrl
    host.mapsKey = configuration.mapsKey
    host.privacyPolicyUrl = configuration.privacyPolicyUrl
    host.useFooter = configuration.useFooter
    host.useLocalCookieBanner = configuration.useLocalCookieBanner
    host.useNewRGPDPolicy = configuration.useNewRGPDPolicy
    host.webOwnerName = configuration.webOwnerName
  }
  return {
    ...toRefs(host),
    initializeHost
  }
}
