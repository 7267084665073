<script setup lang="ts">
import { useAppointmentStore } from '@/store'
import { computed, onMounted, reactive, ref } from 'vue'
import {
  isMobile,
  SAlertType,
  SCustomList,
  SListItem,
  SMainToolbar,
  SProgressCircular
} from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import WorkshopMap from '@/components/organims/WorkshopMap/WorkshopMap.vue'
import { IMarketWorkshopDto } from 'online-customer-services-ts'
import { createEmptyWorkshop } from '@/helpers/constructors.ts'
import { createARelevantMessage } from '@/utils'

const { translate } = useTranslator()

const appointmentStore = useAppointmentStore()
const isLoading = ref(false)

const workshopsList = computed(() => {
  return appointmentStore.workshops
})

const currentWorkshop = reactive<IMarketWorkshopDto>(createEmptyWorkshop())

const handleMarker = (workshop: IMarketWorkshopDto) => {
  Object.assign(currentWorkshop, workshop)
  appointmentStore.workshopSelected = workshop
}

onMounted(async () => {
  isLoading.value = true
  appointmentStore.wasSentRequest = false
  try {
    await appointmentStore.getWorkshops()
  } catch (error) {
    createARelevantMessage(translate('Error.Step.Workshop'), SAlertType.ERROR)
  } finally {
    isLoading.value = false
  }
})
</script>

<template>
  <div class="flex h-full gap-spacing-m overflow-y-hidden p-spacing-s pt-spacing-s">
    <div v-if="!isMobile" class="sm:w-4/6">
      <workshop-map
        :workshops="appointmentStore.workshops"
        :current-workshop="currentWorkshop"
        @click:marker="handleMarker"
      />
    </div>
    <div class="h-full w-full overflow-y-auto pb-spacing-s sm:w-2/6 sm:pb-0">
      <s-main-toolbar
        class="pb-spacing-s"
        :title="translate('AppointmentWizard.Steps.Step1.TitleList')"
      />
      <div v-if="!isLoading" class="">
        <s-custom-list :items="workshopsList">
          <template #default>
            <template v-for="workshop in workshopsList" :key="workshop.name">
              <s-list-item
                :class="{ 'bg-color-neutral-150': workshop.id === currentWorkshop.id }"
                :primary-text="workshop.name"
                :secondary-text="workshop.addressPart1"
                :additional-text="workshop.addressPart2"
                @item-click="handleMarker(workshop)"
              />
            </template>
          </template>
        </s-custom-list>
      </div>
      <div v-else class="flex size-full items-center justify-center">
        <s-progress-circular />
      </div>
    </div>
  </div>
</template>
