import { useAlert } from '@/composables'
import { SAlertType } from 'sima-suite-components-vue3'
import { AvailableHourForAdviserDto2, IWorkshopAvailabilityDto2 } from 'online-customer-services-ts'

export const createARelevantMessage = (message: string, type: SAlertType) => {
  const { addAlert } = useAlert()
  addAlert({
    text: message,
    type
  })
}

export const createWorkshopDate = (date: string, time: string) => {
  return new Date(`${date}T${time}Z`)
}

export const changeLocationHash = (hash: string) => {
  window.location.hash = `#${hash}`
}

export const getTeamId = (availabilityList: IWorkshopAvailabilityDto2[], selectedDate: string) => {
  let adviser: AvailableHourForAdviserDto2 | undefined
  let index = -1
  for (let i = 0; i < availabilityList.length; i++) {
    adviser = availabilityList[i].advisers?.find((adviser) => {
      return adviser.adviserDate === selectedDate
    })
    if (adviser) {
      index = i
      break
    }
  }

  // setContext('aditional-information', {
  //   availabilityItem: availabilityList[index],
  //   index,
  //   selectedDate,
  //   adviser
  // })

  return availabilityList[index]?.teamId
}
