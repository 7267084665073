<script setup lang="ts">
import { SChip, SCustomList, SListItem, SMainToolbar } from 'sima-suite-components-vue3'

import { useTranslator } from 'sima-suite-translator-vue3'
import { useAppointmentStore } from '@/store'
import { computed } from 'vue'
import { IAvailableHourForAdviserDto } from 'online-customer-services-ts'

const appointmentStore = useAppointmentStore()

const { translate } = useTranslator()

const image = new URL(`../../assets/step-5.svg`, import.meta.url).href

const advisers = computed(() => {
  return appointmentStore.availableAdvisers
})

const handleClickAdviser = (adviser: any) => {
  appointmentStore.workshopAvailabilityAdviser = adviser
}

const isAdviserSelected = (adviser: IAvailableHourForAdviserDto) => {
  return (
    adviser.adviserTime === appointmentStore.workshopAvailabilityAdviser?.adviserTime &&
    adviser.adviserName === appointmentStore.workshopAvailabilityAdviser?.adviserName
  )
}
</script>

<template>
  <div class="flex h-full gap-spacing-m overflow-y-hidden p-spacing-s pt-spacing-s">
    <div class="hidden rounded-2xl border border-[#E0E0E0] px-spacing-l py-16 sm:block sm:w-3/5">
      <img :src="image" :alt="translate('AppointmentWizard.Steps.Step5.AltImage')" />
    </div>
    <div class="h-full w-full overflow-y-hidden sm:w-2/6">
      <s-main-toolbar
        class="pb-spacing-s"
        :title="translate('AppointmentWizard.Steps.Step5.WorkshopAvailability')"
      />
      <s-custom-list :items="advisers">
        <s-list-item
          v-for="(adviser, index) in advisers"
          :key="index"
          :class="{
            'bg-color-neutral-150': isAdviserSelected(adviser)
          }"
          :primary-text="adviser.adviserName!"
          @item-click="handleClickAdviser(adviser)"
        >
          <template #append>
            <s-chip :text="adviser.adviserTime!" />
          </template>
        </s-list-item>
      </s-custom-list>
    </div>
  </div>
</template>

<style scoped></style>
