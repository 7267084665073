<script setup lang="ts">
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import { IMarketWorkshopDto } from 'online-customer-services-ts'
import { createEmptyWorkshop } from '@/helpers/constructors.ts'
import { computed, ref, watchEffect } from 'vue'

type WorkshopMapProps = {
  defaultZoom?: number
  workshops: IMarketWorkshopDto[]
  currentWorkshop?: IMarketWorkshopDto
}

const props = withDefaults(defineProps<WorkshopMapProps>(), {
  defaultZoom: 7,
  currentWorkshop: createEmptyWorkshop
})

const emit = defineEmits<{
  (event: 'click:marker', workshop: IMarketWorkshopDto): void
}>()

const latLng = (lat: number, lng: number) => {
  return { lat, lng }
}

const map = ref()
const tileLayerUrl = ref('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
const attribution = ref(
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
)
const zoom = ref(props.defaultZoom)
const workshopToArray = (workshop: IMarketWorkshopDto) => [workshop.latitude, workshop.longitude]
const bounds = computed(() => [props.workshops.map(workshopToArray)])
const center = ref(latLng(28.291565, -16.629129))

const zoomOnMarker = (workshop: IMarketWorkshopDto) => {
  center.value = latLng(workshop.latitude, workshop.longitude)
  zoom.value = 15
}

watchEffect(() => {
  if (!Number.isNaN(props.currentWorkshop.id)) {
    zoomOnMarker(props.currentWorkshop)
  }
})

const clickWorkshop = (workshop: IMarketWorkshopDto) => {
  zoomOnMarker(workshop)
  emit('click:marker', workshop)
}
</script>

<template>
  <div class="hidden sm:block sm:h-full">
    <l-map
      ref="map"
      v-model:zoom="zoom"
      v-model:center="center"
      :bounds="bounds"
      data-testId="map"
      style="height: 100%"
      class="rounded-[0.65rem]"
      :use-global-leaflet="false"
    >
      <l-tile-layer
        :url="tileLayerUrl"
        layer-type="base"
        name="OpenStreetMap"
        :attribution="attribution"
      />
      <l-marker
        v-for="workshop in workshops"
        :key="workshop.id"
        :lat-lng="[workshop.latitude, workshop.longitude]"
        @click="clickWorkshop(workshop)"
      />
    </l-map>
  </div>
</template>
