<script setup lang="ts">
import { ListItem, SIconButton, SList, SMainToolbar } from 'sima-suite-components-vue3'
import WorkshopMap from '@/components/organims/WorkshopMap/WorkshopMap.vue'
import { useEditAppointmentStore } from '@/store'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed } from 'vue'

const { translate, locale } = useTranslator()

const editAppointmentStore = useEditAppointmentStore()

const details = computed(() => {
  return [
    {
      primaryText: editAppointmentStore.appointment.workshop.name!,
      secondaryText: editAppointmentStore.appointment.workshop.addressPart1!,
      showFullText: true,
      icon: 'mdi-map-marker-outline'
    },
    {
      primaryText: `${editAppointmentStore.appointment.vehicle.brand!} ${editAppointmentStore.appointment.vehicle.model!}`,
      secondaryText: editAppointmentStore.appointment.vehicle.plate,
      additionalText: `${editAppointmentStore.appointment.vehicle.kms} km`,
      icon: 'mdi-car-outline'
    },
    {
      primaryText: new Date(editAppointmentStore.workshopAvailabilityDate).toLocaleString(
        locale.value,
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour12: false
        }
      ),
      secondaryText: editAppointmentStore.workshopAvailabilityAdviser?.adviserTime,
      showFullText: true,
      icon: 'mdi-calendar-outline'
    },
    {
      primaryText: editAppointmentStore.appointment.customer.name,
      secondaryText: editAppointmentStore.appointment.customer.email,
      additionalText: phonesToText(editAppointmentStore.appointment.customer),
      showFullText: true,
      icon: 'mdi-account-outline'
    }
  ]
})

const phonesToText = (contact: any): string => {
  return [contact.mobile, contact.phone].filter(Boolean).join(' - ')
}
</script>

<template>
  <div
    class="h-[calc(100vh_-_19rem)] px-spacing-m pt-spacing-s sm:h-[calc(100vh_-_18rem)] md:h-[calc(100vh_-_23rem)]"
  >
    <div class="flex h-full w-full overflow-y-hidden p-spacing-s pt-spacing-s sm:gap-spacing-m">
      <div class="sm:w-4/6">
        <workshop-map
          :workshops="[editAppointmentStore.appointment.workshop]"
          :current-workshop="editAppointmentStore.appointment.workshop"
        />
      </div>

      <div class="w-full overflow-y-hidden sm:w-2/5">
        <s-main-toolbar
          class="pb-spacing-s"
          :title="translate('AppointmentWizard.Steps.Step7.Summary')"
        />
        <div class="h-full overflow-y-auto pb-spacing-xl">
          <s-list :items="details as ListItem[]" show-full-text>
            <template #prependItem="detail">
              <div class="mr-spacing-xs">
                <s-icon-button title="icon" :icon="detail.icon as unknown as string" />
              </div>
            </template>
          </s-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
