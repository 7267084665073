<script setup lang="ts">
import { computed, ref } from 'vue'
import { useHost } from '@/composables'
import { useTranslator } from 'sima-suite-translator-vue3'
import DataTable from '@/components/organims/DataTable/DataTable.vue'

const { webOwnerName, privacyPolicyUrl } = useHost()
const { translate } = useTranslator()

const webOwner = computed(() => webOwnerName.value)
const privacyPolicy = computed(() => privacyPolicyUrl.value)

const cookiesInfomation = ref([
  {
    Cookie: {
      value: '_ga',
      visible: true
    },

    Descripcion: {
      value: 'usada para distinguir usuarios',
      visible: true
    },
    Categoria: {
      value: 'Cookie funcional',
      visible: true
    },
    Persistencia: {
      value: '2 años',
      visible: true
    }
  },
  {
    Cookie: {
      value: '_gid',
      visible: true
    },

    Descripcion: {
      value: 'usada para distinguir usuarios',
      visible: true
    },
    Categoria: {
      value: 'Cookie funcional',
      visible: true
    },
    Persistencia: {
      value: '24 horas',
      visible: true
    }
  },
  {
    Cookie: {
      value: '_gat_<property-id>',
      visible: true
    },

    Descripcion: {
      value: 'usada para controlar la ratio de peticiones',
      visible: true
    },
    Categoria: {
      value: 'Cookie funcional',
      visible: true
    },
    Persistencia: {
      value: '1 minuto',
      visible: true
    }
  }
])
</script>

<template>
  <div class="h-[calc(100vh_-_3.25rem)] overflow-auto bg-[#fafafa] p-[2rem]">
    <h1 class="text-center text-[2rem] font-bold">
      {{ translate('OnlineCustomerServices.CookiesPolicies') }}
    </h1>
    <p>
      En cumplimiento de lo establecido el art. 22.2 de la Ley 34/2002 de Servicios de la Sociedad
      de la información (LSSI), desde {{ webOwner }} le informamos que para la correcta navegación y
      uso de esta página web se requiere de la instalación de cookies.
    </p>
    <h2 class="text-[1.25rem] font-black">
      Información sobre protección de datos. Artículo 13 RGPD:
    </h2>
    <p>
      Los datos recabados a través de la tecnología cookie serán tratados con la legitimación del
      consentimiento del interesado, en aquellos casos en que sea necesario (no lo será para las
      cookies técnicas y de personalización), y la finalidad de la instalación y por ende
      tratamiento de datos personales asociados al uso de esta tecnología será:
    </p>
    <ul>
      <li>
        La configuración de medidas técnicas de seguridad, personalización, cumplimentación de
        formularios, y en general funcionamiento básico de la web, a través de la instalación de
        cookies técnicas.
      </li>
      <li>
        La realización de análisis estadísticos del uso de la web por parte de los visitantes y
        usuarios, a través de la instalación de cookies analíticas.
      </li>
      <li>
        Facilitar a los usuarios el acceso a las cuentas oficiales del titular de la web en las
        distintas redes sociales, o su llegada a las dependencias a través de servicios de mapas
        online; facilitar información y contenidos multimedia de terceros, todo ello a través de la
        instalación de cookies tanto técnicas como analíticas, de terceros.
      </li>
      <li>
        A configuración y muestra de publicidad personalizada o no, a través de la instalación de
        cookies publicitarias básicas, o según se informe específicamente en cada web del Domingo
        Alonso Group, en el apartado de cookies instaladas, de cookies de publicidad comportamental.
      </li>
    </ul>
    <p>
      El plazo de conservación de los datos recogidos por las cookies será por regla general el que
      se indica en la tabla que aparece más adelante en este mismo documento y que será actualizada
      cada 6 meses. Para tener la información más reciente sobre las cookies en uso en cada momento,
      el interesado puede acceder al apartado de configuración e información sobre cookies y
      partners que contiene la herramienta de gestión de cookies instalada en esta web. El plazo de
      conservación contará a partir de la finalización de cada sesión del usuario en la página web.
    </p>
    <p>
      Los datos recabados podrán ser accedidos por los terceros titulares de las webs en calidad
      tanto de corresponsables del tratamiento como de responsables del tratamiento. Todo dependerá
      de cada caso. Igualmente y dados los terceros de los que hacemos uso de librerías de cookies,
      Google y Facebook, la recogida de datos puede suponer una transferencia internacional de datos
      a EEUU, amparada a través de las cláusulas contractuales tipo suscritas con los mismos por las
      distintas empresas de Domingo Alonso Group, a través de sus plataformas web.
    </p>
    <p>
      Usted podrá revocar su consentimiento o configurar sus preferencias en todo momento, a través
      del siguiente enlace. Así mismo puede ejercitar sus derechos de acceso, rectificación,
      oposición, portabilidad o limitación del tratamiento siguiendo las instrucciones facilitadas
      en nuestra <a :href="privacyPolicy">política de privacidad</a>.
    </p>
    <h2 class="text-[1.25rem] font-black">Información sobre la tecnología cookie</h2>
    <h3>¿Qué son las Cookies?</h3>
    <p>
      Las cookies son pequeños ficheros de texto, que guardan información del usuario y se almacenan
      de su propio dispositivo bien sea PC, Tablet o móvil, para luego remitir dicha información a
      los servidores de las páginas web desde donde han sido descargadas.
    </p>
    <p>
      En los casos que corresponda, usted puede elegir prestar o no su consentimiento a la
      instalación de las cookies de {{ webOwner }}, en el caso de no aceptarlas, o de bloquearlas
      por la página puede no ser correcta. Entre los fallos más habituales por la no instalación de
      las cookies se encuentra el error en la carga de contenidos multimedia, la imposibilidad de
      entrar en la zona cliente o de configurar las preferencias de uso de la página.
    </p>
    <h3>¿Qué tipos de cookies existen?</h3>
    <p>Las cookies pueden catalogarse según 3 criterios:</p>
    <ul>
      <li>
        Entidad que las gestiona: pueden ser del titular de la web (propias) o de otros socios o
        colaboradores, grandes plataformas de internet, y un largo etcétera de (terceros).
      </li>
      <li>
        Plazo en que permanecen activas: Las cookies pueden durar solo lo que dure la sesión del
        usuario en la página web (de sesión) o permanecer por más tiempo que la sesión (cookies
        persistentes)
      </li>
      <li>
        Según su finalidad.
        <ul>
          <li>
            Cookies técnicas y cookies de personalización. Se instalan con fines de seguridad,
            configuración técnica, personalización de la web por parte del usuario, y en general
            funcionamiento básico de la web. Sin ellas la web puede no funcionar adecuadamente, y
            por lo tanto no requieren consentimiento del interesado.
          </li>
          <li>
            Cookies de análisis. Son cookies que recaban información sobre datos estadísticos de uso
            y utilización de la web. Requieren que el usuario consienta su instalación.
          </li>
          <li>
            Cookies publicitarias. Son cookies que muestran a los usuarios publicidad de terceros a
            través de marcos, pop ups, barras o cualquier otra modalidad, a través de la web, así
            como del navegador. Requieren que el usuario consienta su instalación.
          </li>
          <li>
            Cookies de publicidad comportamental. Igual que en el caso anterior, pero recabando
            mayor información del usuario, tanto del uso de la web, como de otras acciones
            realizadas en el navegador u otras webs. Requieren que el usuario consienta su
            instalación.
          </li>
        </ul>
      </li>
    </ul>
    <h3 class="text-[0.875rem] font-bold">¿Cómo administro las cookies de mis dispositivos?</h3>
    <p>
      Usted tiene derecho a elegir si acepta o no, la instalación de aquellas cookies que requieran
      de su consentimiento. En todas las webs de Domingo Alonso Group hemos instalado una
      herramienta de gestión de preferencias de instalación que le permite conocer en cada momento
      las cookies que estamos usando, y configurar sus preferencias al nivel que desee.
    </p>
    <p>
      También puede configurar el bloqueo de todo tipo de cookies a través de su navegador. Esta
      opción puede generar múltiples problemas de uso de la web, pero puede permitirle navegar sin
      cookies aunque con ciertas funcionalidades con un funcionamiento deficiente.
    </p>
    <p>
      Nuestra herramienta también permite que configure a posteriori sus preferencias, permitiéndole
      retirar su consentimiento en cualquier momento.
    </p>
    <h3 class="text-[0.875rem] font-bold">Como configurar las cookies según su navegador:</h3>
    <p>
      Internet Explorer: Herramientas > Opciones de Internet > Privacidad > Configuración. Para más
      información, puede consultar el
      <a href="https://support.microsoft.com/es-es">soporte de Microsoft</a> o la Ayuda del
      navegador.
    </p>
    <p>
      Firefox: Herramientas > Opciones > Privacidad > Historial > Configuración Personalizada. Para
      más información, puede consultar el
      <a href="https://support.mozilla.org/es/">soporte de Mozilla</a> o la Ayuda del navegador.
    </p>
    <p>
      Chrome: Configuración > Mostrar opciones avanzadas > Privacidad -> Configuración de contenido.
      Para más información, puede consultar el
      <a href="https://support.google.com/chrome/?hl=es#topic=9796470">soporte de Google</a> o la
      Ayuda del navegador.
    </p>
    <p>
      Safari: Preferencias > Seguridad. Para más información, puede consultar el
      <a href="https://support.apple.com/es-es/safari">soporte de Apple</a> o la Ayuda del
      navegador.
    </p>
    <p>
      Opera: Configuración > Opciones > Avanzado > Para más información, puede consultar el
      <a href="https://help.opera.com/en/latest/web-preferences/#cookies">soporte de Opera</a> o la
      Ayuda del navegador.
    </p>
    <p>
      *Estas configuraciones pueden no estar disponibles en dispositivos móviles tales como tabletS
      o smartphones.
    </p>
    <p>
      *Si usa otro navegador distinto a los anteriores, consulte su política de instalación, uso y
      bloqueo de cookies.
    </p>
    <h3 class="text-[0.875rem] font-bold">Información sobre cookies utilizadas en esta web</h3>
    <p>
      La siguiente es una relación de las cookies usadas en esta página web. Esta lista no tiene
      carácter limitativo y dado que se actualiza cada 6 meses en el momento de acceder a esta web
      puede estar desactualizada. La lista concreta de cookies activas en cada momento la podrá
      encontrar en el apartado de configuración de preferencias de la herramienta que hemos
      dispuesto al efecto en la web, DIDOMI. Puede acceder a ella en cualquier momento a través de
      <a href="javascript:Didomi.preferences.show()">este enlace</a>
    </p>
    <p>
      <data-table :items="cookiesInfomation" with-divider />
    </p>
    <h3 class="text-[0.875rem] font-bold">
      Actualización de la política de cookies y uso de otras cookies.
    </h3>
    <p>
      Las plataformas web y todas las herramientas online evolucionan continuamente y con ellas la
      tecnología que aplican para maximizar el rendimiento de sus servicios, por tanto, es muy
      posible que a futuro existan cambios asociados tanto a la tipología de cookies, como de los
      partners con los que trabajamos, o de plazos de conservación o legitimaciones para los
      tratamientos realizados. Domingo Alonso Group se compromete a mantener lo más actualizada
      posible esta política de privacidad, así como la información facilitada a través de la
      herramienta de gestión de cookies instalada.
    </p>
    <p>
      Si algún cambio de los introducidos requiere de su consentimiento, le informaremos de dicha
      situación previamente, o bien durante su acceso a la propia web.
    </p>
  </div>
</template>

<style scoped>
a {
  @apply text-blue-600 hover:underline;
}

li {
  @apply ml-5 list-inside list-disc;
}

p {
  @apply mb-4;
}
</style>
