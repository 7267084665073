import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/es'
dayjs.extend(utc)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export default dayjs
