import { ICustomer, IMarketWorkshopDto, IVehicle } from 'online-customer-services-ts'
import { Vehicle } from '@/helpers/types.ts'

export const createEmptyWorkshop = (): IMarketWorkshopDto => ({
  id: NaN,
  name: '',
  addressPart1: '',
  addressPart2: '',
  startingWorkHour: '07:00',
  endingWorkHour: '16:00',
  schedulerTimeSlot: NaN,
  brands: [],
  latitude: NaN,
  longitude: NaN,
  timeZoneId: '',
  noWorkingWeekDays: [],
  simaId: NaN,
  idInEnterprise: NaN,
  daysOfMarginToRequest: 0,
  phone: '',
  redirectUrl: ''
})

export const createEmptyVehicle = (): Vehicle => ({
  brand: undefined,
  kilometers: undefined,
  model: '',
  plate: '',
  vin: ''
})

export const createEmptyModifyVehicle = (): IVehicle => {
  return {
    brand: undefined,
    model: undefined,
    plate: undefined,
    vin: undefined,
    kms: undefined,
    brandCode: undefined
  }
}

export const createEmptyModifyCustomer = (): ICustomer => {
  return {
    name: '',
    phone: '',
    mobile: '',
    email: ''
  }
}
