<template>
  <table
    ref="tableContainer"
    class="min-w-full rounded-radius-sr bg-color-white shadow-shadow-s"
    :class="{
      'divide-y divide-color-neutral-200': withDivider
    }"
  >
    <thead>
      <tr>
        <template v-for="(_value, key) in items[0]" :key="key">
          <th
            v-if="_value.visible"
            scope="col"
            class="h3-typography px-spacing-m py-spacing-xs text-left text-color-neutral-450"
          >
            <span class="capitalize">{{ key }}</span>
          </th>
        </template>
      </tr>
    </thead>
    <tbody
      :class="{
        'divide-y divide-color-neutral-200': withDivider
      }"
      data-testid="table-body"
    >
      <tr
        v-for="(row, idx) in items"
        :key="idx"
        class="cursor-pointer hover:bg-color-neutral-50"
        @click="$emit('row:click', row)"
      >
        <template v-for="(field, index) in row" :key="index">
          <td
            v-if="field.visible"
            class="b2-typography truncate whitespace-nowrap px-spacing-m py-spacing-s"
            :style="{ maxWidth: columnSize }"
          >
            <img
              v-if="isAImageField(field.value)"
              :src="field.value.src"
              :alt="field.value.altText ? field.value.altText : 'image'"
              class="h-spacing-m w-spacing-m"
            />
            <span v-else>
              {{ field.value || '-' }}
            </span>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

type DataTableProps = {
  items: Record<string, any>[]
  withDivider?: boolean
}

const props = withDefaults(defineProps<DataTableProps>(), {
  withDivider: false
})

defineEmits<{
  (event: 'row:click', row: Record<string, any>): void
}>()
const tableContainer = ref<HTMLElement>()
const columnSize = ref('0px')

const getTableWidth = () => {
  if (props.items.length > 0 && tableContainer.value?.clientWidth) {
    const numberOfColumns = Object.entries(props.items[0]).length
    columnSize.value = `${tableContainer.value?.clientWidth / numberOfColumns}px`
  }
}

onMounted(() => {
  getTableWidth()
  window.addEventListener('resize', getTableWidth)
})

const isAImageField = (field: any) => {
  return field && typeof field === 'object' && field['src']
}
</script>
