<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { isMobile, SButtonSize, SElevationTokens } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { SButton, SDivider, SSheet } from 'sima-suite-components-vue3'
import { useAppointmentStore } from '@/store'
import { useRouter } from 'vue-router'
import { useHost } from '@/composables'
import { ROUTE_MAIN } from '@/router/routeNames.ts'

const appointmentStore = useAppointmentStore()
const { translate } = useTranslator()
const { brandLogo } = useHost()
const router = useRouter()
const buttonSize = SButtonSize.big
const title = ref(history.state.title)
const description = ref(history.state.description)

const logo = computed(() => {
  if (brandLogo.value) {
    return new URL(`../../assets/themes/logos/${brandLogo.value}`, import.meta.url).href
  }
  return ''
})

onBeforeMount(() => {
  if (!appointmentStore.wasSentRequest) {
    router.push({ name: ROUTE_MAIN })
  }
  appointmentStore.resetAvailability()
})

const goToStart = () => {
  router.push({ name: ROUTE_MAIN })
}
</script>

<template>
  <s-sheet
    :elevation="isMobile ? SElevationTokens.null : SElevationTokens.s"
    class="w-full p-spacing-m sm:ml-auto sm:mr-auto sm:mt-spacing-xxxl sm:h-fit sm:w-4/5 sm:drop-shadow-sm xl:w-3/5"
  >
    <div
      class="font-['Work Sans'] flex h-28 items-center justify-center px-spacing-m py-spacing-s text-[2rem] font-bold sm:justify-start"
    >
      <img v-if="logo" :alt="translate('AppointmentWizard.LogoAlt')" :src="logo" class="h-full" />
      <h1 v-else class="font-['Work Sans'] text-neutral text-center text-[2rem] font-bold">OCS</h1>
    </div>

    <s-divider />

    <div
      class="align-center m-spacing-m flex h-[calc(100vh_-_16.375rem)] flex-col justify-around overflow-y-auto rounded-[1rem] border border-[#E0E0E0] p-spacing-s sm:h-[calc(100vh_-_24.875rem)]"
    >
      <img
        src="@/assets/confirm.svg"
        :alt="translate('AppointmentConfirm.AltImage')"
        class="h-[70%] min-h-[12.5rem] py-spacing-l"
      />

      <div class="text-center">
        <h2 class="text-neutral mb-spacing-xs text-2xl font-bold">
          {{ title }}
        </h2>
        <p class="text-neutral text-sm font-normal">
          {{ description }}
        </p>
      </div>
    </div>

    <div
      class="absolute bottom-1 right-8 flex w-full items-center justify-end p-spacing-s sm:relative sm:bottom-0 sm:right-0 sm:p-spacing-m"
    >
      <s-button
        :size="buttonSize"
        :text="`${translate('Appointment.GoToStart')}`"
        color="primary"
        flat
        @click="goToStart()"
      />
    </div>
  </s-sheet>
</template>
