import { reactive } from 'vue'
import { type AlertMessage } from 'sima-suite-components-vue3'

const messages = reactive<AlertMessage[]>([])

export const useAlert = () => {
  const addAlert = (msg: AlertMessage) => {
    messages.push(msg)
  }

  const removeAlert = () => {
    messages.shift()
  }

  const clearAlerts = () => {
    messages.splice(0, messages.length)
  }

  return {
    messages,
    addAlert,
    removeAlert,
    clearAlerts
  }
}
