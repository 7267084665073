import {
  BrandsClient,
  WorkshopsClient,
  MarketServicesClient,
  VehiclesClient,
  WorkshopAvailabilityClient,
  AppointmentsClient,
  CookiesUrlClient
} from 'online-customer-services-ts'
import { reactive } from 'vue'

const clients = reactive<{ [key: string]: any }>({
  workshops: new WorkshopsClient(),
  brands: new BrandsClient(),
  services: new MarketServicesClient(),
  vehicles: new VehiclesClient(),
  workshopAvailability: new WorkshopAvailabilityClient(),
  appointmentsClient: new AppointmentsClient(),
  cookiesClient: new CookiesUrlClient()
})

const getClient = (key: string) => {
  return clients[key]
}

export const useAutogenerateClients = () => {
  const initClients = (baseUrl: string) => {
    clients.workshops = new WorkshopsClient(baseUrl)
    clients.brands = new BrandsClient(baseUrl)
    clients.services = new MarketServicesClient(baseUrl)
    clients.vehicles = new VehiclesClient(baseUrl)
    clients.workshopAvailability = new WorkshopAvailabilityClient(baseUrl)
    clients.appointmentsClient = new AppointmentsClient(baseUrl)
    clients.cookiesClient = new CookiesUrlClient(baseUrl)
  }

  return {
    initClients,
    getClient
  }
}
