import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { App } from 'vue'

export class Release {
  constructor(
    private name: string,
    private version: string
  ) {}

  getReleaseName = (): string => `${this.name}@${this.version}`
}

export interface MonitoringConfig {
  release: Release
  dsn: string
  router: Router
  backendUrl: string
}
export function initMonitoring(app: App<Element>, config: MonitoringConfig) {
  const { release, dsn, router, backendUrl } = config

  Sentry.init({
    app,
    dsn: dsn,
    integrations: [
      // Or omit `router` if you're not using vue-router
      Sentry.browserTracingIntegration({ router })
    ],
    release: release.getReleaseName(),
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    logErrors: false,
    tracePropagationTargets: ['localhost', backendUrl]
  })
}
