import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'sima-suite-components-vue3/dist/style.css'
import 'sima-suite-components-vue3/dist/icons.css'
import './assets/tailwind.css'
import './assets/fonts/fonts.css'
import './veeValidateRules.ts'
import App from './App.vue'
import { getRouter } from './router'
import { configureSimaSuiteComponents } from 'sima-suite-components-vue3'
import { configureVeeValidateLocales, loadConfiguration } from './configuration'
import { configureTranslations } from 'sima-suite-translator-vue3'
import translations from './translations'
import { createGtm } from '@gtm-support/vue-gtm'
import { useHost, useAutogenerateClients } from '@/composables'
import themes from '@/assets/themes'
import loadDidomi from '@/utils/didomiWrapper'
import { initMonitoring, Release } from '@/plugins/sentry.ts'
import packageInfo from '../package.json'
const enableMocking = async () => {
  if (import.meta.env.MODE !== 'development') {
    return
  }

  const worker = await import('./helpers/msw/browser').then((m) => m.default)
  return worker.start({ onUnhandledRequest: 'bypass' })
}

await enableMocking()

configureTranslations(translations)
configureVeeValidateLocales()
const { initClients } = useAutogenerateClients()
const { initializeHost } = useHost()
const configuration = await loadConfiguration()

if (configuration.didomiId) {
  loadDidomi(document, configuration.didomiId)
}

const theme = themes[configuration.host] || themes.default

initializeHost({
  id: configuration.host,
  brandColor: theme.brandColor,
  brandFont: theme.brandFont,
  brandLogo: theme.brandLogo,
  conditionsUrl: '',
  didomiId: configuration.didomiId,
  cookiesUrl: '',
  legalAdviserUrl: configuration.legalAdviserUrl,
  mapsKey: configuration.mapsKey,
  privacyPolicyUrl: configuration.privacyPolicyUrl,
  useFooter: configuration.useFooter,
  useLocalCookieBanner: configuration.useLocalCookieBanner,
  webOwnerName: configuration.webOwnerName,
  useNewRGPDPolicy: configuration.useNewRGPDPolicy
})
initClients(configuration.backendUrl)

const app = createApp(App)
app.use(createPinia())
const router = getRouter()
app.use(router)

const themeProduct = {
  color: {
    primary: {
      '500': theme.brandColor
    }
  }
}

if (import.meta.env.PROD) {
  const { version, name } = packageInfo
  initMonitoring(app, {
    release: new Release(name, version),
    dsn: 'https://d2a7114c526d480d844b3559d53cd1be@o1427692.ingest.sentry.io/4505431958290432',
    router,
    backendUrl: configuration.backendUrl
  })
}

configureSimaSuiteComponents(app, themeProduct)

/* Replace with you GTag id */
app.use(
  createGtm({
    id: configuration.googleAnalyticsUA,
    parentElement: document.head
  })
)
app.mount('#app')
