import { useAutogenerateClients, useHost } from '@/composables'

export const getCookiesUrl = async () => {
  const { getClient } = useAutogenerateClients()
  const { id, cookiesUrl } = useHost()
  const cookiesClient = getClient('cookiesClient')
  const data = await cookiesClient.getCookiesUrl(id.value)
  const { url } = data

  cookiesUrl.value = url
}

export const decodeCookie = (cname: string) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}
