import { createRouter, createWebHistory, type Router } from 'vue-router'
import AppView from '@/views/AppView/AppView.vue'
import {
  ROUTE_APPOINTMENT_CONFIRM,
  ROUTE_APPOINTMENT_FAILURE,
  ROUTE_EDIT_APPOINTMENT,
  ROUTE_COOKIES_POLICY,
  ROUTE_MAIN,
  ROUTE_MODIFY_APPOINTMENT
} from '@/router/routeNames.ts'
import AppointmentConfirm from '@/views/AppointmentConfirm/AppointmentConfirm.vue'
import AppointmentFailure from '@/views/AppointmentFailure/AppointmentFailure.vue'
import CookiesPolicy from '@/views/CookiesPolicy/CookiesPolicy.vue'
import SummaryEditView from '@/views/SummaryEditView/SummaryEditView.vue'
import ModifyView from '@/views/ModifyView/ModifyView.vue'
import { checkIfRedirectToMainView, fromEditViewGuard } from '@/router/helpers.ts'

export const getRouter = (): Router => {
  return createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: ROUTE_MAIN,
        component: AppView
      },
      {
        path: '/confirm',
        name: ROUTE_APPOINTMENT_CONFIRM,
        component: AppointmentConfirm
      },
      {
        path: '/failure',
        name: ROUTE_APPOINTMENT_FAILURE,
        component: AppointmentFailure
      },
      {
        path: '/cookies-policy',
        name: ROUTE_COOKIES_POLICY,
        component: CookiesPolicy
      },
      {
        path: '/edit',
        name: ROUTE_EDIT_APPOINTMENT,
        beforeEnter: [checkIfRedirectToMainView],
        component: SummaryEditView
      },
      {
        path: '/edit/modify',
        name: ROUTE_MODIFY_APPOINTMENT,
        component: ModifyView,
        beforeEnter: [fromEditViewGuard]
      }
    ]
  })
}
