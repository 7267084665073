<script setup lang="ts">
import { useTranslator } from 'sima-suite-translator-vue3'
import {
  SMainToolbar,
  STextField,
  STextFieldType,
  STextFieldVariants
} from 'sima-suite-components-vue3'
import NewRGPDPolicy from '@/components/molecules/NewRGPDPolicy/NewRGPDPolicy.vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/rules'
import { ref, watch } from 'vue'
import { useHost } from '@/composables'
import { useAppointmentStore } from '@/store'

const { translate } = useTranslator()

const appointmentStore = useAppointmentStore()

const image = new URL(`../../assets/step-6.svg`, import.meta.url).href

type FormFields = {
  name: string
  email: string
  mobilePhone: string
  phone: string
}

const { errors, defineField, meta } = useForm<FormFields>({
  validationSchema: toTypedSchema({
    name: {
      required: true
    },
    email: {
      required: true,
      email: true
    },
    mobilePhone: {
      requiredIf: 'phone',
      max: 15
    },
    phone: {
      requiredIf: 'mobilePhone',
      max: 15
    }
  }),
  validateOnMount: false
})

const { useNewRGPDPolicy } = useHost()

const [name] = defineField('name')
const [email] = defineField('email')
const [mobilePhone] = defineField('mobilePhone')
const [phone] = defineField('phone')

const check = ref(false)

const getFormErrors = (field: 'name' | 'email' | 'phone' | 'mobilePhone', translation: string) => {
  return errors.value[field]?.replace(field, translation)
}

watch([name, email, mobilePhone, phone, check, meta], () => {
  appointmentStore.contactDetails.name = name.value
  appointmentStore.contactDetails.email = email.value
  appointmentStore.contactDetails.mobilePhone = mobilePhone.value
  appointmentStore.contactDetails.phone = phone.value

  if (meta.value.valid && check.value && !useNewRGPDPolicy.value) {
    appointmentStore.isContactFormValid = true
  } else appointmentStore.isContactFormValid = meta.value.valid && useNewRGPDPolicy.value
})
</script>

<template>
  <div class="flex h-full gap-spacing-m overflow-y-hidden p-spacing-s pt-spacing-s">
    <div class="hidden rounded-2xl border border-[#E0E0E0] px-spacing-l py-16 sm:block sm:w-3/5">
      <img :src="image" :alt="translate('AppointmentWizard.Steps.Step6.AltImage')" />
    </div>
    <div class="flex w-full flex-col overflow-y-auto pb-spacing-s sm:w-2/5 sm:pb-0">
      <form>
        <s-main-toolbar
          class="pb-spacing-s"
          :title="translate('AppointmentWizard.Steps.Step6.ContactInfo')"
        />
        <s-text-field
          v-model:value="name"
          :type="STextFieldType.text"
          :label="`${translate('AppointmentWizard.Steps.Step6.CompleteName')}*`"
          :variant="STextFieldVariants.underlined"
          color="primary"
          :placeholder="translate('AppointmentWizard.Steps.Step6.CompleteName')"
          :error-messages="
            getFormErrors('name', translate('AppointmentWizard.Steps.Step6.CompleteName'))
          "
        />
        <s-text-field
          v-model:value="email"
          :type="STextFieldType.email"
          :label="`${translate('AppointmentWizard.Steps.Step6.Email')}*`"
          :variant="STextFieldVariants.underlined"
          color="primary"
          :placeholder="translate('AppointmentWizard.Steps.Step6.Email')"
          :error-messages="getFormErrors('email', translate('AppointmentWizard.Steps.Step6.Email'))"
        />
        <s-text-field
          v-model:value="mobilePhone"
          :type="STextFieldType.number"
          :label="`${translate('AppointmentWizard.Steps.Step6.Mobile')}**`"
          :variant="STextFieldVariants.underlined"
          color="primary"
          :placeholder="translate('AppointmentWizard.Steps.Step6.Mobile')"
          :error-messages="
            getFormErrors('mobilePhone', translate('AppointmentWizard.Steps.Step6.Mobile'))
          "
        />
        <s-text-field
          v-model:value="phone"
          :type="STextFieldType.number"
          :label="`${translate('AppointmentWizard.Steps.Step6.Phone')}**`"
          :variant="STextFieldVariants.underlined"
          color="primary"
          :placeholder="translate('AppointmentWizard.Steps.Step6.Phone')"
          :error-messages="getFormErrors('phone', translate('AppointmentWizard.Steps.Step6.Phone'))"
        />
      </form>
      <new-r-g-p-d-policy v-model:check="check" />
    </div>
  </div>
</template>
