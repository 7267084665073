<script setup lang="ts">
import { SButton, SButtonSize, SButtonVariant } from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'

const { translate } = useTranslator()

type WorkshopFooter = {
  isNextDisabled: boolean
  isFirstStep: boolean
  isLastStep: boolean
  isConfirmLoading?: boolean
}

defineProps<WorkshopFooter>()

const emit = defineEmits<{
  (e: 'click:next'): void
  (e: 'click:back'): void
  (e: 'click:confirm'): void
}>()

const handleNextClick = () => {
  emit('click:next')
}

const handleBackClick = () => {
  emit('click:back')
}

const handleConfirmClick = () => {
  emit('click:confirm')
}
</script>

<template>
  <div class="flex justify-end gap-spacing-s rounded-b-2xl bg-color-white p-spacing-s">
    <s-button
      v-if="!isFirstStep"
      :size="SButtonSize.big"
      flat
      :variant="SButtonVariant.text"
      :text="translate('AppointmentWizard.Back')"
      @button-click="handleBackClick"
    />
    <s-button
      v-if="!isLastStep"
      :disabled="isNextDisabled"
      :size="SButtonSize.big"
      flat
      :text="translate('AppointmentWizard.Next')"
      @button-click="handleNextClick"
    />
    <s-button
      v-if="isLastStep"
      :is-loading="isConfirmLoading"
      :size="SButtonSize.big"
      flat
      :text="translate('AppointmentWizard.Confirm')"
      @button-click="handleConfirmClick"
    />
  </div>
</template>
