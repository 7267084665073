<script setup lang="ts">
import { computed } from 'vue'
import { isMobile } from 'sima-suite-components-vue3'

type SWizardProps = {
  steps: string[]
  step: number
  color?: string
}

const props = withDefaults(defineProps<SWizardProps>(), {
  color: '#1573e5'
})

const emit = defineEmits<{
  (e: 'update:step', step: number): void
}>()

const offset = computed(() => (props.step === 0 || props.step === props.steps.length - 1 ? 2 : 1))

const showStep = (step: number): boolean => {
  if (isMobile.value) {
    const minShown = props.step - offset.value >= 0 ? props.step - offset.value : 0
    const maxShown =
      props.step + offset.value < props.steps.length
        ? props.step + offset.value
        : props.steps.length - 1

    return step >= minShown && step <= maxShown
  }

  return true
}

const onStep = (newStep: number) => {
  if (newStep <= props.step) {
    emit('update:step', newStep)
  }
}
</script>

<template>
  <div class="flex h-[4rem] justify-evenly">
    <template v-for="(nameStep, i) in steps" :key="i">
      <button
        type="button"
        :class="{
          hidden: !showStep(i),
          'hover:bg-color-neutral-100': i < step,
          'before:-bottom-[1.25rem] before:right-[50%] before:h-0.5 before:w-[calc(100%_-_3rem)]':
            i > 0,
          'background-color-before': isMobile && i <= step,
          'before:bg-color-neutral-200': isMobile && i > step,
          'after:-top-[2.5rem] after:left-[50%] after:h-0.5 after:w-[calc(100%_-_3rem)]':
            i < steps.length - 1,
          'background-color-after': !isMobile && i < step,
          'after:bg-color-neutral-200':
            (!isMobile && i >= step) || (isMobile && i === step + offset)
        }"
        class="flex h-full w-96 cursor-pointer flex-col items-center justify-evenly before:relative after:relative"
        @click="onStep(i)"
      >
        <span
          :class="{
            'border-color background-color text-color-white': i < step,
            'border-color text-color bg-color-white': i === step,
            'border-color-neutral-200 bg-color-neutral-50 text-color-neutral-300': i > step
          }"
          class="mx-spacing-xs grid h-spacing-xl w-spacing-xl items-center justify-center rounded-full border-2 text-sm font-normal"
        >
          {{ i + 1 }}
        </span>
        <span
          :class="{
            'text-color-neutral-450': i < step,
            'text-color': i === step,
            'text-color-neutral-250': i > step
          }"
          class="truncate text-xs font-normal"
        >
          {{ nameStep }}
        </span>
      </button>
    </template>
  </div>
</template>

<style scoped>
.text-color {
  color: v-bind(color);
}
.border-color {
  border-color: v-bind(color);
}
.background-color {
  background-color: v-bind(color);
}
.background-color-before::before {
  content: '';
  background-color: v-bind(color);
}
.background-color-after::after {
  content: '';
  background-color: v-bind(color);
}
</style>
